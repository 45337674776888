<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
export default {
  methods : {
    setMeta(route){
      if(route.meta.title){
        let setTitle = route.meta.title;
        document.title = setTitle;
      }

      if(route.meta.desc){
        let setDesc = route.meta.desc;
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      }
    }
  },
  mounted(){
    let route = this.$route;
    this.setMeta(route);
  },
  watch: { 
    '$route' (route) {
      this.setMeta(route);
    }
  }
}
</script>

<style lang="sass">

// #fffcfa
// #062f48
// #0172b7
// #7ee9f9
// #deb54c

*
  box-sizing: border-box
  margin: 0
  padding: 0
  border: none
  list-style: none

body
  background-color: #fffcfa
  color: #062f48

a
  color: #062f48
  text-decoration: none

.contents
  width: calc(100vw - 300px)
  position: absolute
  top: 70px
  right: 0
  @media (max-width: 768px)
    width: 100%

.annotation
  text-align: left
  font-size: 0.8em

.button
  border: none
  border-radius: 5px
  padding: 5px 15px
  background-color: #0172b7
  color: #7ee9f9
  cursor: pointer
  transition: color 0.2s, background-color 0.2s
  user-select: none
  &:hover
    background-color: lighten(#0172b7, 10%)
    color: lighten(#7ee9f9, 10%)
  
  &.success-button
    background-color: #5cb85c
    color: #fff
    &:hover
      background-color: lighten(#5cb85c, 10%)
      color: lighten(#fff, 10%)
  
  &.danger-button
    background-color: #d9534f
    color: #fff
    &:hover
      background-color: lighten(#d9534f, 10%)
      color: lighten(#fff, 10%)
  
  &:disabled
    background-color: #aaa
    color: #fff
    pointer-events: none
    &:hover
      background-color: #aaa
      color: #fff
form:invalid > .button
  background-color: #aaa
  color: #fff
  pointer-events: none
  &:hover
    background-color: #aaa
    color: #fff

.loading
  background-image: url(~@/assets/loading_white.gif)
  background-size: contain
  background-position: center center
  background-repeat: no-repeat
  cursor: default
  pointer-events: none

.loading_blue
  background-image: url(~@/assets/loading_blue.gif)
  background-size: contain
  background-position: center center
  background-repeat: no-repeat
  cursor: default
  pointer-events: none

.loading_red
  background-image: url(~@/assets/loading_red.gif)
  background-size: contain
  background-position: center center
  background-repeat: no-repeat
  cursor: default
  pointer-events: none

.item-list  
  border-collapse: collapse
  background-color: #fff
  color: #062f48
  width: 80%

  thead
    background-color: #0172b7
    color: #fffcfa

  tr
    border: 1px solid #0172b7 

  th, td
    padding: 10px
    text-align: center
    @media (max-width: 768px)
      padding: 3px
      font-size: 0.8em
  
  &.sortable
    th
      cursor: pointer
    th.asc::after
      content: "▲"
    th.desc::after
      content: "▼"

.form-contents
  input, select
    display: block
    border: 1px solid #062f48
    border-radius: 5px
    padding: 10px
    width: 15em
    margin: 20px auto
    outline: none
    font-size: 1.1em
    @media (max-width: 768px)
      width: 100%

.at-review
  display: flex
  align-items: center

  .at-icon
    width: 150px
    height: 150px
    padding: 5%
    background-color: #0172b7
    flex-shrink: 0
    transition: border-radius 0.2s
    @media (max-width: 768px)
      width: 80px
      height: 80px

  .at-info
    margin-left: 20px
    padding: 10px
    display: flex
    align-items: flex-end
    border-bottom: 5px solid #0172b7
    @media (max-width: 768px)
      display: block
      text-align: center
      width: 100%

  .at-name
    font-size: 2.4em
    @media (max-width: 768px)
      font-size: 1.6em

  .at-amount
    margin-left: 20px
    font-size: 2em
    @media (max-width: 768px)
      margin-left: 0
      font-size: 1.4em
  
  .at-no
    margin-left: 20px
    font-size: 2em

    .at-amount
      font-size: 0.9em
      margin: 0

.error-message
  color: #d9534f

.scrollable
  @media (max-width: 768px)
    overflow-x: scroll

.trade-info
  display: flex
  justify-content: center
  align-items: center
  .from, .to
    font-size: 1.6em
    padding: 5px 10px
    border: 1px solid #0172b7
    border-radius: 5px
    color: #ff4500
  .arrow
    font-size: 1.4em
    margin: 0 20px

.shape-circle
  border-radius: 50%
  overflow: visible

.toggle
  display: flex
  button
    width: 100px
    padding: 5px
    border: 1px solid #ccc
    cursor: pointer
    transition: background-color,color 0.2s
    &:first-child
      border-top-left-radius: 15px
      border-bottom-left-radius: 15px 
    &:last-child
      border-top-right-radius: 15px
      border-bottom-right-radius: 15px
      border-left: none
    &.active
      background-color: #333366
      color: #fff

// animations
.fade-enter-active, .fade-leave-active
    transition: opacity 0.2s ease
.fade-enter-from, .fade-leave-to
    opacity: 0

</style>
