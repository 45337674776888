<template>
  <Transition name="modal">
    <div class="modal-wrapper" v-if="isShow">
        <div class="modal">
          <h2>{{ title }}</h2>
          <div class="modal-contents">
            <slot />
          </div>
          <div class="modal-controls" v-if="withCloseButton">
            <button class="button danger-button" @click="hide()">閉じる</button>
          </div>
        </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'MyModal',
  props: {
    title: {
      type: String,
      require: true,
    },
    withCloseButton: {
      type: Boolean,
      require: false,
      default: true,
    }
  },
  data: function () {
    return {
      isShow: false,
    }
  },
  methods: {
    show: function () {
      this.isShow = true
    },
    hide: function () {
      this.isShow = false
      this.$emit('hide')
    }
  }
}
</script>

<style lang="sass" scoped>
.modal-wrapper
  display: flex
  justify-content: center
  align-items: center
  position: fixed
  width: 100%
  height: 100%
  background-color: rgba(160, 160, 160, 0.2)
  z-index: 1000

.modal
  background-color: #fffcfa
  padding: 20px
  border-radius: 5px

  h2
    border-bottom: 3px solid #062f48

.modal-contents
  margin-top: 10px
  padding: 10px

.modal-controls
  margin-top: 10px
  text-align: center
  .button
    padding: 10px 15px
    font-size: 1em

// modal animation
.modal-enter-active, .modal-leave-active
    transition: opacity 0.2s ease, padding-top 0.2s ease
.modal-enter-from, .modal-leave-to
    opacity: 0
    padding-top: 100px

</style>
