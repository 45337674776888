<template>
  <div class="base">
    <SideBar />
    <div class="contents">
      <HeaderBar />
      <div class="list-contents">
        <h2>Asirtie一覧</h2>
        <div class="controls">
          <p>表示モード切替</p>
          <div class="display-mode toggle">
            <button class="display-mouseover active" ref="displayModeAllButton" @click="selectAll">全て</button>
            <button class="display-click" ref="displayModeMyselfButton" @click="selectMyself">自分のみ</button>
          </div>
        </div>
        <div class="scrollable">
          <Transition name="fade">
            <table class="item-list sortable" v-if="isLoaded">
              <thead>
                <tr>
                  <th></th>
                  <th :class="orderColumn == 'product__name' ? orderDirection == '-' ? 'desc' : 'asc' : ''" colspan="2" @click="changeSort('product__name')">Asirtie</th>
                  <th>No.</th>
                  <th :class="orderColumn == 'traded_at' ? orderDirection == '-' ? 'desc' : 'asc' : ''" @click="changeSort('traded_at')">日付</th>
                  <th :class="orderColumn == 'from_user__profile__name' ? orderDirection == '-' ? 'desc' : 'asc' : ''" @click="changeSort('from_user__profile__name')">送信元</th>
                  <th :class="orderColumn == 'to_user__profile__name' ? orderDirection == '-' ? 'desc' : 'asc' : ''" @click="changeSort('to_user__profile__name')">送信先</th>
                  <th>受け取り地点の住所</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(transaction, i) in transactionList" :key="i">
                  <tr :class="transaction.latest_message ? 'two-line-1' : ''">
                    <td>
                      <router-link :to="`/atmessage/${transaction.id}`">
                        <img
                          class="comment-icon"
                          src="@/assets/icon_comment.png">
                      </router-link>
                    </td>
                    <td>
                      <img
                        :class="transaction.product.shape == 1 ? 'at-icon' : 'at-icon shape-circle'"
                        :style="'background-color: ' + transaction.product.color"
                        :src="transaction.product.icon.url">
                    </td>
                    <td class="table-at-name">{{ transaction.product.name }}</td>
                    <td>
                      No. {{ transaction.index }} / {{ transaction.product.amount }}
                    </td>
                    <td>{{ $moment(transaction.traded_at) }}</td>
                    <td>{{ transaction.from_user.profile.name }}</td>
                    <td>{{ transaction.to_user.profile.name }}</td>
                    <td>{{ transaction.address }}</td>
                  </tr>
                  <tr v-if="transaction.latest_message" class="two-line-2">
                    <td></td>
                    <td colspan="6" class="latest-message">
                      <p>最新のコメント ({{ $moment(transaction.latest_message.created_at) }})</p>
                      <p class="message">{{ transaction.latest_message.message }}</p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div class="loading" v-else></div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import HeaderBar from "../components/HeaderBar.vue"
import SideBar from "../components/SideBar.vue"

export default {
  name: 'ATListPage',
  components: {
    HeaderBar,
    SideBar,
  },
  data: function () {
    return {
      isLoaded: false,
      transactionList: [],
      orderColumn: 'traded_at',
      orderDirection: '-',
      userId: null,
    }
  },
  methods: {
    readTransactionList: function () {
      api.transactions.read({
        isActive: 1,
        isDiagram: 0,
        order: this.orderDirection + this.orderColumn,
      }).then((response) => {
        this.transactionList = response.data
        this.isLoaded = true
      }).catch((error) => {
        api.errors.create({ message: error })
      })
    },
    changeSort: function (column) {
      if (this.orderColumn == column) {
        this.orderDirection = this.orderDirection == '-' ? '' : '-'
      } else {
        this.orderColumn = column
        this.orderDirection = '-'
      }

      this.readTransactionList()
    },
    selectAll() {

      if (this.displayMode == "all") {
        return
      }

      this.readTransactionList()

      this.displayMode = "all"
      this.$refs.displayModeAllButton.classList.add("active")
      this.$refs.displayModeMyselfButton.classList.remove("active")
    },
    selectMyself() {

      if (this.displayMode == "myself") {
        return
      }

      const userId = api.getHeader()['X-USER-ID']
      this.transactionList = this.transactionList.filter((transaction) => {
        return transaction.from_user.id == userId || transaction.to_user.id == userId
      })

      this.displayMode = "myself"
      this.$refs.displayModeMyselfButton.classList.add("active")
      this.$refs.displayModeAllButton.classList.remove("active")
    },
  },
  mounted: function () {
    const header = api.getHeader()
    this.userId = header['X-USER-ID']

    this.readTransactionList()
  },
}
</script>

<style lang="sass" scoped>
.base
  display: flex

.list-contents
  padding: 30px
  @media (max-width: 768px)
    padding: 10px

.item-list
  width: 90%
  margin-top: 20px
  @media (max-width: 768px)
    width: auto

  .two-line-1
    border-bottom: none
  .two-line-2
    border-top: none

  td:nth-child(2), td:nth-child(3)
    width: 1px
    white-space: nowrap
  td:nth-child(3)
    text-align: left

  td
    white-space: nowrap

  .table-at-name
    text-align: left

  .latest-message
    text-align: left
    .message
      border-radius: 5px
      border: 1px solid #aaa
      font-weight: bold
      white-space: pre-wrap
      padding: 10px
      background-color: #fffcfa

.controls
  display: flex
  align-items: center
  margin-top: 10px
  p
    margin-right: 10px

.at-icon
  width: 50px
  height: 50px
  padding: 7px
  background-color: #0172b7
  flex-shrink: 0
  @media (max-width: 768px)
    width: 30px
    height: 30px
    padding: 5px

.comment-icon
  width: 40px
  height: 40px
  @media (max-width: 768px)
    width: 30px
    height: 30px

.loading
  width: 50px
  height: 50px
  margin: 10px
  border-radius: 5px
  background-color: #0172b7
</style>
