<template>
  <div class="base">
    <SideBar />
    <div class="contents">
      <HeaderBar />
      <div class="diagram-field">
        <h2>Asirtie関係図</h2>
        <div class="controls">
          <p>表示モード切替</p>
          <div class="display-mode toggle">
            <button class="display-mouseover active" ref="displayModeAllButton" @click="selectAll">全て</button>
            <button class="display-click" ref="displayModeMyselfButton" @click="selectMyself">自分のみ</button>
          </div>
        </div>
        <div class="loading" v-if="!isLoaded"></div>
        <div id="network"></div>
        <ProductTooltip ref="tooltip" />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { Network } from "vis-network"
import { DataSet } from "vis-data"
import HeaderBar from "../components/HeaderBar.vue"
import SideBar from "../components/SideBar.vue"
import ProductTooltip from "../components/ProductTooltip.vue"

export default {
  name: 'DiagramPage',
  components: {
    HeaderBar,
    SideBar,
    ProductTooltip,
  },
  data: function () {
    return {
      isLoaded: false,
      displayMode: "all",
      nodes: [],
      edges: [],
      network: null,
    }
  },
  methods: {
    selectAll() {
  
      if (this.displayMode == "all") {
        return
      }

      if (this.network) {
        this.network.destroy()
      }
      this.network = this.drawDiagram(this.nodes, this.edges)

      this.displayMode = "all"
      this.$refs.displayModeAllButton.classList.add("active")
      this.$refs.displayModeMyselfButton.classList.remove("active")
    },
    selectMyself() {

      if (this.displayMode == "myself") {
        return
      }

      if (this.network) {
        this.network.destroy()
      }

      const header = api.getHeader()
      const userId = header['X-USER-ID']

      // 自分に直接繋がっているエッジと製品ノードを取得
      const relatedProductEdgeList = this.edges.filter((edge) => {
        return edge.from == `user_${userId}` || edge.to == `user_${userId}`
      })
      const relatedProductNodeList = this.nodes.filter((node) => {
        return node.group == 'product' && relatedProductEdgeList.some((edge) => {
          return edge.from == node.id || edge.to == node.id
        })
      })

      // 各製品ノードに繋がっているエッジとユーザーノードを取得
      const relatedUserEdgeList = []
      const relatedUserNodeList = []
      for (const productNode of relatedProductNodeList) {

        const tmpRelatedProductEdgeList = this.edges.filter((edge) => {
          return edge.from == productNode.id || edge.to == productNode.id
        })
        relatedUserEdgeList.push(...tmpRelatedProductEdgeList)

        relatedUserNodeList.push(...this.nodes.filter((node) => {
          return tmpRelatedProductEdgeList.some((edge) => {
            return edge.from == node.id || edge.to == node.id
          })
        }))
      }
      
      const relatedEdgeList = Array.from(new Set(relatedProductEdgeList.concat(relatedUserEdgeList)))
      const relatedNodeList = Array.from(new Set(relatedProductNodeList.concat(relatedUserNodeList)))

      this.network = this.drawDiagram(relatedNodeList, relatedEdgeList)

      this.displayMode = "myself"
      this.$refs.displayModeMyselfButton.classList.add("active")
      this.$refs.displayModeAllButton.classList.remove("active")
    },
    drawDiagram(nodes, edges) {
      var container = document.getElementById("network")
      var data = {
        nodes: new DataSet(nodes),
        edges: new DataSet(edges),
      }
      var options = {
        groups: {
          user: {
            color: "#062f48",
            font: "16px arial #fffcfa",
            shape: "box",
            margin: {
              top: 15,
              right: 20,
              bottom: 15,
              left: 20,
            },
          },
          product: {
            color: "#fffcfa",
            shape: "circle",
            margin: 10,
          },
          more: {
            color: "#fffcfa",
            shape: "circle",
          },
        },
        edges: {
          color: "#062f48",
          arrows: {
            to: {
              enabled: true,
              scaleFactor: 1,
              type: "arrow", 
            },
          },
        },
      }
      const network = new Network(container, data, options)
      const clickEvent = (event) => {
        const node = event.nodes[0]
        
        if (node && node.substr(0, 7) == 'product') {
          const clickedNode = data.nodes.get(node)
          this.clickedNode = clickedNode
          this.$refs.tooltip.show(clickedNode)
        } else {
          this.clickedNode = null
        }
      }
      network.on('click', clickEvent)

      return network
    },
  },
  mounted: function () {
    api.transactions.read({
      isActive: 1,
      isDiagram: 1,
      order: "-traded_at"
    }).then((response) => {
      this.nodes = response.data.nodes
      this.edges = response.data.edges
      this.network = this.drawDiagram(this.nodes, this.edges)
      this.isLoaded = true
    }).catch((error) => {
      api.errors.create({ message: error })
    })
  }
}
</script>

<style lang="sass" scoped>
.base
  display: flex

h2
  margin: 30px 0 0 30px
  @media (max-width: 768px)
    margin: 10px 0 0 10px

#network
  width: 100%
  height: calc(100vh - 100px - 2.3em)
  cursor: grab
  outline: none
  z-index: 10
  @media (max-width: 768px)
    height: calc(100vh - 80px - 2.3em)

.controls
  display: flex
  align-items: center
  margin: 10px 0 0 30px
  p
    margin-right: 10px

.loading
  width: 50px
  height: 50px
  margin: 10px
  margin-left: 30px
  border-radius: 5px
  background-color: #0172b7
</style>
