<template>
  <div class="news-bar">
    <ul class="news-list">
      <li><span>よろしければアンケートにご協力お願いします。 →<a href="">アンケートを開く</a></span></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NewsBar',
  data: function () {
    return {}
  },
  methods: {},
  mounted: function () {},
}
</script>

<style lang="sass" scoped>
.news-bar
  width: 100%
  
.news-list
  li
    width: 100%
    height: 60px
    padding: 0 20px 0 60px
    display: flex
    align-items: center
    background-color: lighten(#deb54c, 30%)
    background-image: url(~@/assets/news.svg)
    background-size: 30px 30px
    background-repeat: no-repeat
    background-position: 20px center
</style>
