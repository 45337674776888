<template>
  <HeaderBar :show-news="true" />
  <main>
    <div class="login-box">
      <h1 class="site-title">Asirtie</h1>
      <div class="logo">
        <img src="@/assets/logo.png" alt="">
      </div>
      <p>受け取ったAsirtieに最初のコメントを付けましょう</p>
      <div class="form-contents">
        <form @submit.prevent="postMessage">
          <fieldset class="transaction-message">
            <textarea v-model="message" placeholder="よろしくお願いします！"></textarea>
          </fieldset>
          <fieldset class="transaction-location">
            <button class="button success-button" @click.prevent="getAddress">あなたの現在位置を知らせる</button>
            <input type="text" v-model="address"  placeholder="ボタンを押すとここに住所が入力されます。">
          </fieldset>
          <button class="button submit-button" type="submit">送信</button>
        </form>
      </div>
      <div class="next">
        <router-link class="next-link" to="/">ホームに戻る</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import api from '@/api'
import HeaderBar from '../components/HeaderBar.vue'

export default {
  name: 'TradedMessagePage',
  components: {
    HeaderBar,
  },
  data: function () {
    return {
      transactionId: this.$route.query.transactionId,
      isLoaded: false,
      transaction: null,
      message: null,
      latitude: null,
      longitude: null,
      address: null,
    }
  },
  methods: {
    getAddress() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude
        this.longitude = position.coords.longitude

        api.addresses.read({
          latitude: this.latitude,
          longitude: this.longitude,
        }).then((response) => {
          this.address = response.data.address
        }).catch((error) => {
          api.errors.create({ message: error })
        })
      })
    },
    postMessage() {
      api.transactionMessages.create({
        transactionId: this.transactionId,
        message: this.message,
        latitude: this.latitude,
        longitude: this.longitude,
        address: this.address,
      }).then(() => {
        this.$router.push('/')
      }).catch((error) => {
        api.errors.create({ message: error })
      })
    },
  },
  mounted: function () {
    // api.transactions.detail.read({ transactionId: this.$route.query.transactionId }).then((response) => {
    //   console.log(response.data)
    //   this.transaction = response.data
    // }).catch((error) => {
    //   api.errors.create({ message: error })
    // }).finally(() => {
    //   this.isLoaded = true
    // })
  }
}
</script>

<style lang="sass" scoped>
.login-box
  width: 100%
  padding: 20px

.site-title
  font-size: 3.5em

.logo
  img
    height: 70px

main
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  text-align: center

.loading_blue
  width: 100px
  height: 100px
  background-color: lighten(#deb54c, 30%)
  margin: 20px auto 0 auto
  border-radius: 5px

.at-review
  background-color: lighten(#deb54c, 30%)
  border-radius: 5px
  padding: 10px
  margin-top: 20px

.trade-info
  margin-top: 20px

.next
  margin-top: 20px
.next-link
  color: #0172b7

header
  width: 100%

.form-contents
  margin-top: 30px
  .transaction-message
    margin-top: 20px
    textarea
      width: 400px
      height: 100px
      border: 1px solid #ccc
      border-radius: 5px
      padding: 5px
      font-size: 16px
      @media (max-width: 768px)
        width: 100%
        font-size: 12px
  .transaction-location
    margin-top: 20px
    button
      width: 250px
      margin-top: 10px
    input
      width: 400px
      border: 1px solid #ccc
      border-radius: 5px
      padding: 5px
      margin-top: 10px
      font-size: 12px
      @media (max-width: 768px)
        width: 100%
  .submit-button
    width: 250px
    margin-top: 20px
    font-size: 16px
  
</style>
