<template>
  <Transition name="fade">
    <div class="tooltip-wrapper" v-if="isShow">
      <div v-if="isLoaded">
        <div class="tooltip">
          <div class="close-button" @click="hide"><span></span><span></span></div>
          <div class="at-review">
            <img
              :class="transaction.product.shape == 2 ? 'at-icon shape-circle' : 'at-icon'"
              :style="'background-color: ' + transaction.product.color"
              :src="transaction.product.icon ? transaction.product.icon.url : require('@/assets/figure_carrot.png')">
            <div class="at-info">
              <div class="at-name">{{ transaction.product.name }}</div>
              <div class="at-no">No. {{ transaction.index }} / <span class="at-amount">{{ transaction.product.amount }}</span></div>
            </div>
          </div>
          <div class="at-address">
            <p>受け取り地点の住所:</p>
            <p class="address">{{ transaction.address ? transaction.address : '不明'}}</p>
          </div>
          <table class="item-list" v-if="userId == transaction.from_user.id || userId == transaction.to_user.id">
            <thead>
              <tr>
                <th>ユーザー名</th>
                <th>メッセージ</th>
                <th>日付</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transactionMessage, i) in transactionMessageList" :key="i">
                <td class="user-name">{{ transactionMessage.user.profile.name }}</td>
                <td class="message-view">
                  <div class="message">{{ transactionMessage.message }}</div>
                </td>
                <td>{{ $moment(transactionMessage.created_at) }}</td>
              </tr>
            </tbody>
          </table>
          <router-link :to="`/atmessage/${transaction.id}`">
            <button class="button success-button comment-button">
              <img class="comment-icon" src="@/assets/icon_comment_white.png">
              コメント詳細へ
            </button>
          </router-link>
        </div>
      </div>
      <div class="loading" v-else></div>
    </div>
  </Transition>
</template>

<script>
import api from '@/api'

export default {
  name: 'ProductTooltip',
  data: function () {
    return {
      userId: null,
      isShow: false,
      isLoaded: false,
      transaction: null,
      transactionMessageList: [],
    }
  },
  methods: {
    show: function (node) {
      this.isShow = true

      const splitted = node.id.slice(8).split('_')
      const productId = parseInt(splitted[0])
      const index = parseInt(splitted[1])

      // transactionを取得する
      api.transactions.read({
        isActive: 1,
        productId: productId,
        index: index,
      }).then((response) => {
        this.transaction = response.data[0]

        api.transactionMessages.read({
          transactionId: this.transaction.id,
        }).then((response) => {
          this.transactionMessageList = response.data
          this.isLoaded = true
        }).catch((error) => {
          api.errors.create({ message: error })
        })
      }).catch((error) => {
        api.errors.create({ message: error })
      })
    },
    hide: function () {
      this.isShow = false
      this.isLoaded = false
      this.$emit('hide')
    }
  },
  mounted: function () {
    this.userId = api.getHeader()['X-USER-ID']
  }
}
</script>

<style lang="sass" scoped>
.tooltip-wrapper
  display: flex
  justify-content: center
  align-items: center
  position: fixed
  width: 100%
  height: 100%
  z-index: 1000
  top: 0
  left: 0

.tooltip
  position: relative
  background-color: #062f48
  color: #fff
  padding: 10px
  border-radius: 5px
  max-width: 500px
  max-height: 500px
  overflow-y: scroll

  .at-review
    margin-top: 15px
    .at-icon
      width: 50px
      height: 50px
      padding: 10px
    .at-name
      font-size: 1.6em
    .at-no
      font-size: 1.4em
    .at-amount
      font-size: 1em

  .item-list
    width: 100%
    margin-top: 20px
    @media (max-width: 768px)
      width: auto

    tr
      border: none
      border-top: 1px solid #062f48
    th
      background-color: #062f48
      text-align: center
    th, td
      border-right: 1px solid #062f48 
    th, td
      width: 1px
      white-space: nowrap
    th:nth-child(2), td:nth-child(2)
      width: auto
      white-space: normal
      text-align: left
    .message-view
      .message
        white-space: pre-wrap
    .table-at-name
      text-align: left
    .user-name
      color: #ff4500
      font-weight: bold

.loading
  min-width: 50px
  min-height: 50px
  background-color: #062f48
  border-radius: 5px

.close-button
  position: absolute
  top: 10px
  right: 10px
  cursor: pointer
  background-color: #fff
  border-radius: 5px
  width: 30px
  height: 30px
  span
    position: absolute
    top: 13.5px
    left: 5px
    display: block
    width: 20px
    height: 3px
    background-color: #062f48
  span:first-child
    transform: rotate(45deg)
  span:last-child
    transform: rotate(-45deg)

.at-address
  margin-top: 10px
  .address
    margin: 10px

.comment-button
  margin: 20px auto
  display: flex
  justify-content: center
  align-items: center
  font-size: 1.2em
  padding: 10px
  .comment-icon
    width: 25px
    height: 25px
    margin-right: 10px
</style>
