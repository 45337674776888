<template>
  <main v-if="!isLoading">
    <div class="login-box">
      <h1 class="site-title">Asirtie</h1>
      <div class="logo">
        <img src="@/assets/logo.png" alt="">
      </div>
      <div class="site-description">
        Asirtieは相手（あるいは未来の自分）にメッセージを伝え、「繋がり」を表現するものです。
      </div>
      <form class="login-form" @submit.prevent="confirm()">
        <p class="annotation">※このニックネームは公開されるため、本名等の個人情報の入力はお控えください。</p>
        <input type="text" placeholder="あなたのニックネーム" v-model="name" required>
        <p class="error-message" v-if="nameErrorMessage">{{ nameErrorMessage }}</p>
        <input type="text" placeholder="ニックネームの確認" v-model="nameConfirm" required>
        <p class="error-message" v-if="nameConfirmErrorMessage">{{ nameConfirmErrorMessage }}</p>
        <input type="email" placeholder="メールアドレス" v-model="mail" required>
        <p class="error-message" v-if="mailErrorMessage">{{ mailErrorMessage }}</p>
        <input type="password" placeholder="パスワード" v-model="password" required>
        <p class="error-message" v-if="passwordErrorMessage">{{ passwordErrorMessage }}</p>
        <input type="password" placeholder="パスワード確認" v-model="passwordConfirm" required>
        <p class="error-message" v-if="passwordConfirmErrorMessage">{{ passwordConfirmErrorMessage }}</p>
        <button type="submit" class="button submit-button">登録確認メールを送信</button>
      </form>
      <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
      <div class="login">
        <p>既にアカウントをお持ちの方は</p>
        <router-link
          class="login-link"
          :to="qr ? `/login/?next=/api/qr/verify/&token=${qr}` : '/login/'">
          こちらからログイン
        </router-link>
      </div>
    </div>
    <MyModal title="同意確認" ref="confirmModal" :withCloseButton="false">
      <p class="confirm-message">
        私は、<b>｢研修における人と人の繋がりを示すAsirtie｣</b>について、
        以下の説明書を読み、内容について十分理解しました。
        下記の｢同意する｣ボタンをクリックすることにより私がこのプロジェクトに
        参加することを自由意思で決定したことを示します。
      </p>
      <div class="controls">
        <button class="button success-button" ref="submitButton" @click="agree()">同意する</button>
        <button class="button danger-button" @click="disagree()">同意しない</button>
      </div>
      <div class="explanation">
        <h3>｢研修における人と人の繋がりを示すAsirtie｣についての説明書</h3>
        <ol class="top-level">
          <li>
            本プロジェクトは、｢研修における人と人の繋がりを示すAsirtie｣をメッセージとして
            相手（あるいは未来の自分）に伝え、「繋がり」を表現するものです。
          </li>
          <li>
            <h4>使い方</h4>
            <ol>
              <li>
                本説明書に同意していた開ける場合は下にある同意するのボタンを押した後､
                次のページでニックネームとメールアドレスとパスワードを登録します｡
              </li>
              <li>
                Asirtieを自分で発行し、繋がりを記録したい他者に渡します｡
                QRコードを見せるだけで渡すことができます｡
              </li>
              <li>
                Asirtieを貰った人は同じように自身が主役となってAsirtieを発行し、
                渡すことでさらに繋がりを記録していくことができます｡
              </li>
              <li>
                このように繋がりをAsirtieというトークンを使って記録していく仕組みがAsirtieです｡
                自分で複数の異なるAsirtieを作ることもできます｡
              </li>
            </ol>
          </li>
          <li>
            <h4>取得するデータ</h4>
            <ul>
              <li>
                本プロジェクトに参加することで、登録時間､メールアドレス､パスワード､ニックネーム､
                作成したAsirtieの情報、アンケート情報（任意参加）を取得します｡なおこれらの情報は
                「国立大学法人室蘭工業大学個人情報等管理規程」に基づき厳重に管理します｡
              </li>
              <li>
                <p>本プロジェクトで取得した情報は，以下の目的のために室蘭工業大学が使用します。</p>
                <ul>
                  <li>サービス提供のため</li>
                  <li>地域のSocial Capitaの分析のため</li>
                  <li>Asirtieの効果測定のため</li>
                </ul>
              </li>
              <li>
                取得したデータの保管期間は、１年です。変更する際にはお知らせします｡
              </li>
              <li>
                取得したデータは、室蘭工業大学が，分析結果を個人が特定できないように統計処理し，
                論文、報告書、プレゼンテーション資料、インターネットホームページ等の著作物に
                掲載する場合があります．
              </li>
            </ul>
          </li>
          <li>
            <h4>利用者の権利</h4>
            <ul>
              <li>利用者は自らの自由意志でいつでもプロジェクトから抜けることができる。</li>
              <li>万一不利益をこうむった場合に、室蘭工業大学に対して申し立てを行うことができる。</li>
            </ul>
          </li>
        </ol>
        <p>なおご意見等は  info@asirtie.comまでお寄せください｡</p>
      </div>
    </MyModal>
  </main>
  <main v-else>
    <div class="loading"></div>
  </main>
</template>

<script>
import api from '@/api'
import MyModal from "../components/MyModal.vue"

export default {
  name: 'SignupPage',
  components: {
    MyModal,
  },  data: function () {
    return {
      name: null,
      nameConfirm: null,
      mail: null,
      password: null,
      passwordConfirm: null,
      errorMessage: null,
      nameErrorMessage: null,
      nameConfirmErrorMessage: null,
      mailErrorMessage: null,
      passwordErrorMessage: null,
      passwordConfirmErrorMessage: null,
      qr: this.$route.query.qr,
      isLoading: true,
    }
  },
  methods: {
    confirm() {
      this.errorMessage = null
      this.nameErrorMessage = null
      this.nameConfirmErrorMessage = null
      this.mailErrorMessage = null
      this.passwordErrorMessage = null
      this.passwordConfirmErrorMessage = null

      if (this.name != this.nameConfirm) {
        this.nameConfirmErrorMessage = 'ニックネームが一致しません。'
      }

      if (this.password != this.passwordConfirm) {
        this.passwordConfirmErrorMessage = 'パスワードが一致しません。'
      }

      if (this.passwordConfirmErrorMessage || this.nameConfirmErrorMessage) {
        return
      }

      this.$refs.confirmModal.show()
    },
    agree() {
      this.$refs.submitButton.classList.add('loading')

      api.auth.signup({
        name: this.name,
        mail: this.mail,
        password: this.password,
        qr: this.qr,
      }).then((response) => {
        const userId = response.data.id
        if (this.qr) {
          this.$router.push(`/resend/?userId=${userId}&qr=${this.qr}`)
        } else {
          this.$router.push(`/resend/?userId=${userId}`)
        }
      }).catch((error) => {
        switch (error.response.status) {
          case 400:
            if (error.response.data.name) {
              this.nameErrorMessage = error.response.data.name[0]
            }
            if (error.response.data.mail) {
              this.mailErrorMessage = error.response.data.mail[0]
            }
            if (error.response.data.password) {
              this.passwordErrorMessage = error.response.data.password[0]
            }
            break
          case 422:
            this.errorMessage = '認証メールの送信に失敗しました。使用可能なメールアドレスか確認してください。'
            break
          default:
            this.errorMessage = 'サーバーに問題が発生しました。時間をおいて再度実行してください。'
            break
        }

        api.errors.create({ message: error })

        this.$refs.submitButton.classList.remove('loading')
        this.$refs.confirmModal.hide()
      })
    },
    disagree() {
      this.$refs.confirmModal.hide()
    },
  },
  created: function () {
    if (!this.qr) {
      console.log(this.qr)
      this.isLoading = false
      return
    }

    api.auth.confirm().then(() => {
      api.qr.verify({ token: this.qr }).then((response) => {
        this.$router.push('/traded_message?transactionId=' + response.data.id)
      }).catch((error) => {
        api.errors.create({ message: error })
        this.$router.push('/')
      })
    }).catch((error) => {
      api.errors.create({ message: error })
      this.isLoading = false
    })
  },
}
</script>

<style lang="sass" scoped>
main
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  @media (max-width: 768px)
    height: auto

.site-title
  font-size: 3.5em

.logo
  img
    height: 70px

.login-box
  padding: 30px
  border-radius: 5px
  text-align: center
  width: 600px

  @media (max-width: 768px)
    width: 100%

.login-form
  margin-top: 50px

  input
    display: block
    border: 1px solid #062f48
    border-radius: 5px
    padding: 15px
    width: 380px
    margin: 20px auto
    outline: none
    font-size: 1.2em

    @media (max-width: 768px)
      width: 90%
  
  .annotation
    width: 380px
    margin: 0 auto

    @media (max-width: 768px)
      width: 90%

  .button
    font-size: 1.2em
    height: 2.5em
    margin-top: 20px

.error-message
  margin-top: 20px
.login
  margin-top: 20px
  
  .login-link
    color: #0172b7

.confirm-message
  width: 700px
  margin-top: 20px
  @media (max-width: 768px)
    width: auto

.explanation
  width: 680px
  height: 300px
  background-color: #fff
  overflow-y: scroll
  margin: 20px auto
  padding: 10px
  text-align: left
  @media (max-width: 768px)
    width: auto
  
  h3
    margin-bottom: 10px
    border-bottom: 1px solid #062f48
    padding: 5px
    text-align: center
  h4
    font-weight: normal
  ol
    margin-left: 2em
    li
      list-style: decimal
      ol
        margin-top: 10px
        li
          list-style: none
          counter-increment: cnt
          &::before
            content: counter(cnt) ")"
    &.top-level
      margin-top: 20px
      li
        margin-bottom: 20px
  ul
    margin-top: 10px
    margin-left: 2em
    li
      list-style: disc
      ul
        margin-top: 10px
        li
          list-style: circle

.controls
  margin: 20px auto 0 auto
  display: flex
  justify-content: center
  .button:first-child
    margin-right: 20px

.modal-wrapper
  top: 0

.loading
  width: 50px
  height: 50px
  border-radius: 5px
  background-color: #0172b7
</style>
