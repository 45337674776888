<template>
  <header>
    <nav>
      <div class="left">
        <div class="title">
          <router-link to="/">
            <h1 class="title">Asirtie<img class="logo" src="@/assets/logo.png" alt="Asirtie logo"></h1>
          </router-link>
        </div>
      </div>
      <div class="right">
        <div :class="isLoaded ? 'user-info' : 'user-info loading_blue'">
          <div class="name" v-if="isLoaded">{{ name }}</div>
          <div class="mail-address">{{ mail }}</div>
        </div>
        <HamburgerMenu />
      </div>
    </nav>
    <NewsBar v-if="showNews && false" />
  </header>
</template>

<script>
import api from '@/api'
import NewsBar from '../components/NewsBar.vue'
import HamburgerMenu from "../components/HamburgerMenu.vue"

export default {
  name: 'HeaderBar',
  components: {
    NewsBar,
    HamburgerMenu,
  },
  props: {
    showNews: {
      type: Boolean,
      require: false,
      default: false
    },
  },
  data: function () {
    return {
      name: '読み込み中',
      mail: '読み込み中',
      isLoaded: false,
    }
  },
  mounted: function () {
    if (!this.$user.profile.name || !this.$user.mail) {
      api.users.detail.read({ userId: api.getHeader()['X-USER-ID'] }).then((response) => {
        this.$user = response.data
        this.name = this.$user.profile.name
        this.mail = this.$user.mail
      }).catch((error) => {
        api.errors.create({ message: error })
        window.location.href = '/login/'
      }).finally(() => {
        this.isLoaded = true
      })
    } else {
      this.name = this.$user.profile.name
      this.mail = this.$user.mail
    }
  },
}
</script>

<style lang="sass" scoped>
header
  width: calc(100vw - 300px)
  position: fixed
  top: 0
  right: 0
  z-index: 100
  @media (max-width: 768px)
    width: 100%
nav
  height: 70px
  width: 100%
  border-bottom: 2px solid #eee
  background-color: #fff
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 30px

  @media (max-width: 768px)
    height: 60px
    padding: 0 20px

  .title
    display: none
    font-size: 16px

    @media (max-width: 768px)
      display: block

    h1
      display: flex
      align-items: center
      font-size: 1.4em
    .logo
      height: 1.4em
      margin-left: 5px

  .user-info
    display: flex
    align-items: center
    border: 1px solid #0172b7
    border-radius: 5px
    padding: 5px 15px

    @media (max-width: 768px)
      display: none

  .name
    margin-right: 10px
    color: #ff4500
    font-size: 1.1em
    font-weight: bold

</style>
