<template>
  <div>
    <input type="checkbox" id="hamburgerTrigger">
    <label for="hamburgerTrigger" class="humburger-button">
      <span></span>
      <span></span>
      <span></span>
    </label>
    <label for="hamburgerTrigger" class="menu-wrapper">
      <menu class="menu">
        <div class="header" v-on:click.stop.prevent>
          <div :class="isLoaded ? 'user-info' : 'user-info loading_blue'">
            <div class="name" v-if="isLoaded">{{ name }}</div>
            <div class="mail-address">{{ mail }}</div>
          </div>
        </div>
        <ul>
          <li>
            <router-link class="menu-link" to="/atcreate">Asirtieを使う</router-link>
          </li>
          <li>
            <router-link class="menu-link" to="/atlist">Asirtie一覧</router-link>
          </li>
          <li>
            <router-link class="menu-link" to="/diagram">Asirtie関係図</router-link>
          </li>
          <li @click="logout" ref="hamburgerLogoutButton">
            <span class="menu-link">ログアウト</span>
          </li>
        </ul>
      </menu>
    </label>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'HamburgerMenu',
  data: function () {
    return {
      name: '読み込み中',
      mail: '読み込み中',
      isLoaded: false,
    }
  },
  methods: {
    logout() {
      this.$refs.hamburgerLogoutButton.classList.add('loading_blue')

      api.auth.logout().then(() => {
        document.cookie = "token=; max-age=0; path=/;"
        document.cookie = "userid=; max-age=0; path=/;"
        this.$router.push('/login/')
      }).catch((error) => {
        api.errors.create({ message: error })
        this.$refs.hamburgerLogoutButton.classList.remove('loading_blue')
      })
    }
  },
  mounted: function () {
    if (!this.$user.profile.name || !this.$user.mail) {
      api.users.detail.read({ userId: api.getHeader()['X-USER-ID'] }).then((response) => {
        this.$user = response.data
        this.name = this.$user.profile.name
        this.mail = this.$user.mail
      }).catch((error) => {
        api.errors.create({ message: error })
      }).finally(() => {
        this.isLoaded = true
      })
    } else {
      this.name = this.$user.profile.name
      this.mail = this.$user.mail
    }
  },
}
</script>

<style lang="sass" scoped>
#hamburgerTrigger
  display: none

.humburger-button
  display: none
  text-align: center
  cursor: pointer

  span
    display: block
    width: 35px
    height: 4px
    background-color: #062f48
    border-radius: 2px
    transition: all 0.2s
  span:first-child
    margin-bottom: 6px
  span:last-child
    margin-top: 6px

  @media (max-width: 768px)
    display: block

#hamburgerTrigger:checked+.humburger-button
  span:nth-child(2)
    opacity: 0
  span:first-child
    transform: rotate(45deg)
    margin-bottom: -4px
  span:last-child
    transform: rotate(-45deg)
    margin-top: -4px

.menu-wrapper
  display: none
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  height: calc(100% - 60px)

.header
  padding: 10px

  .user-info
    border: 1px solid #0172b7
    border-radius: 5px
    padding: 5px 15px

  .name
    margin-right: 10px
    color: #ff4500
    font-size: 1.1em
    font-weight: bold

  .mail-address
    font-size: 0.8em

.menu
  position: absolute
  top: 0px
  right: 0
  background-color: #fff
  box-shadow: -1px 1px 3px #ccc

  ul
    li
      border-top: 1px solid #eee

    .menu-link
      width: 100%
      height: 100%
      padding: 10px
      display: flex
      align-items: center

#hamburgerTrigger:checked+label+.menu-wrapper
  display: block
</style>
