<template>
  <MyModal :title="title" :with-close-button="false" ref="myModal">
    <slot />
    <div class="modal-controls">
      <button class="button danger-button" @click="destroy()" ref="deleteButton">削除する</button>
      <button class="button success-button" @click="hide()">キャンセル</button>
    </div>
  </MyModal>
</template>

<script>
import api from '@/api'
import MyModal from "../components/MyModal.vue"

export default {
  name: 'DeleteModal',
  props: ['title', 'action', 'after'],
  components: {
    MyModal,
  },
  methods: {
    show: function () {
      this.$refs.myModal.show()
    },
    hide: function () {
      this.$refs.myModal.hide()
    },
    destroy: function () {
      this.$refs.deleteButton.classList.add('loading_red')

      this.action().then(() => {
        this.after()
        this.hide()
      }).catch((error) => {
        api.errors.create({ message: error })
        this.$refs.deleteButton.classList.remove('loading_red')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.modal-controls
  text-align: center
  margin-top: 10px
  .button
    padding: 10px 15px
    font-size: 1em
    &:first-child
      margin-right: 10px
</style>
