import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from './pages/LoginPage.vue'
import SignupPage from './pages/SignupPage.vue'
import SignupVerifyPage from './pages/SignupVerifyPage.vue'
import HomePage from './pages/HomePage.vue'
import ATCreatePage from './pages/ATCreatePage.vue'
import ATListPage from './pages/ATListPage.vue'
import DiagramPage from './pages/DiagramPage.vue'
import TradedPage from './pages/TradedPage.vue'
import ConfirmPage from './pages/ConfirmPage.vue'
import TradedMessagePage from './pages/TradedMessagePage.vue'
import ATMessagePage from './pages/ATMessagePage.vue'
import api from '@/api'


const description = 'Asirtieは人と人の繋がりを通じて生産者からのメッセージとして生産物と一緒に利用者に｢繋がり｣を伝えるものです｡これはCOI-NEXTの一貫で進めている北海道白糠町発信のプロジェクトです｡'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      title: 'ホーム | Asirtie',
      desc: description,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage, 
    meta: {
      title: 'ログイン | Asirtie',
      desc: description,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupPage, 
    meta: {
      title: '新規登録 | Asirtie',
      desc: description,
    },
  },
  {
    path: '/resend',
    name: 'resend',
    component: SignupVerifyPage,
    meta: {
      title: '登録確認 | Asirtie',
      desc: description,
    },
  },
  {
    path: '/atcreate',
    name: 'atcreate',
    component: ATCreatePage, 
    meta: {
      title: 'Asirtieを使う | Asirtie',
      desc: description,
    },
  },
  {
    path: '/traded',
    name: 'traded',
    component: TradedPage, 
    meta: {
      title: '取引完了 | Asirtie',
      desc: description,
    },
  },
  {
    path: '/atlist',
    name: 'atlist',
    component: ATListPage,
    meta: {
      title: 'Asirtie一覧 | Asirtie',
      desc: description,
    },
  },
  {
    path: '/diagram',
    name: 'diagram',
    component: DiagramPage,
    meta: {
      title: 'Asirtie関係図 | Asirtie',
      desc: description,
    },
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: ConfirmPage,
    meta: {
      title: '同意の確認 | Asirtie',
      desc: description,
    },
  },
  {
    path: '/traded_message',
    name: 'traded_message',
    component: TradedMessagePage,
    meta: {
      title: 'Asirtieの受け取りメッセージ | Asirtie',
      desc: description,
    },
  },
  {
    path: '/atmessage/:id',
    name: 'transaction_message',
    component: ATMessagePage,
    meta: {
      title: 'Asirtieコメント | Asirtie',
      desc: description,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const exclude_path = [
    '/login',
    '/login/',
    '/signup',
    '/signup/',
    '/resend',
    '/resend/',
  ]

  if (!exclude_path.includes(to.path)) {
    api.auth.confirm()
    .then(() => next())
    .catch(() => next('/signup/'))
  } else {
    next()
  }
})

export default router
