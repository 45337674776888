<template>
  <div class="base">
    <SideBar />
    <div class="contents">
      <HeaderBar />
      <div class="list-contents">
        <h2><router-link class="prev-link" to="/atlist">Asirtie一覧</router-link> - コメント</h2>
        <Transition name="fade">
          <div class="at-review" v-if="isTransactionLoaded">
            <img
              :class="transaction.product.shape == 1 ? 'at-icon' : 'at-icon shape-circle'"
              :style="'background-color: ' + transaction.product.color"
              :src="transaction.product.icon.url">
            <div class="at-info">
              <div class="at-name">{{ transaction.product.name }}</div>
              <div class="at-amount">{{ transaction.index }} / {{ transaction.product.amount }} Asirtie</div>
            </div>
          </div>
          <div class="loading" v-else></div>
        </Transition>
        <div class="scrollable">
          <Transition name="fade">
            <table class="item-list" v-if="isTransactionMessageListLoaded">
              <thead>
                <tr>
                  <th>ユーザー名</th>
                  <th>コメント</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(transactionMessage, i) in transactionMessageList" :key="i">
                  <td class="user-name">{{ transactionMessage.user.profile.name }}</td>
                  <td class="message-view">
                    <template v-if="selectedTransactionMessage?.id == transactionMessage.id">
                      <textarea placeholder="メッセージを入力してください。" v-model="updateMessage" required></textarea><br>
                      <button class="button success-button" @click="updateTransactionMessage">送信</button>
                    </template>
                    <template v-else>
                      <div class="message">{{ transactionMessage.message }}</div>
                      <div class="date">【{{ $moment(transactionMessage.created_at) }}】</div>
                    </template>
                  </td>
                  <td>
                    <template v-if="userId == transactionMessage.user.id">
                      <button
                        class="button danger-button"
                        @click="cancelTransactionMessage"
                        v-if="selectedTransactionMessage?.id == transactionMessage.id">中止</button>
                      <button class="button success-button" v-else @click="selectTransactionMessage(transactionMessage)">編集</button>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="loading" v-else></div>
          </Transition>
        </div>
        <Transition name="fade">
          <div class="form-contents" v-if="isTransactionLoaded && isTransactionMessageListLoaded && userId == transaction.to_user.id">
            <h3>コメントを追加 (100件まで)</h3>
            <form @submit.prevent="postTransactionMessage">
              <fieldset class="message">
                <textarea placeholder="コメントを入力してください。" v-model="message" required></textarea>
              </fieldset>
              <button class="button submit-button" type="submit">送信</button>
            </form>
          </div>
        </Transition>
        <router-link class="prev-link" to="/atlist">Asirtie一覧に戻る</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import HeaderBar from "../components/HeaderBar.vue"
import SideBar from "../components/SideBar.vue"

export default {
  name: 'ATMessagePage',
  components: {
    HeaderBar,
    SideBar,
  },
  data: function () {
    return {
      userId: api.getHeader()['X-USER-ID'],
      isTransactionLoaded: false,
      isTransactionMessageListLoaded: false,
      selectedTransactionMessage: null,
      transaction: {},
      transactionMessageList: [],
      message: '',
      updateMessage: null,
    }
  },
  methods: {
    readTransaction: function () {
      api.transactions.detail.read({
        transactionId: this.$route.params.id,
      }).then((response) => {
        this.transaction = response.data
        this.isTransactionLoaded = true
      }).catch((error) => {
        api.errors.create({ message: error })
      })
    },
    readTransactionMessageList: function () {
      api.transactionMessages.read({
        transactionId: this.$route.params.id,
      }).then((response) => {
        this.transactionMessageList = response.data
        this.isTransactionMessageListLoaded = true
      }).catch((error) => {
        api.errors.create({ message: error })
      })
    },
    postTransactionMessage: function () {
      api.transactionMessages.create({
        transactionId: this.$route.params.id,
        message: this.message,
      }).then(() => {
        this.readTransactionMessageList()
      }).catch((error) => {
        api.errors.create({ message: error })
      })
    },
    selectTransactionMessage: function (transactionMessage) {
      this.updateMessage = transactionMessage.message
      this.selectedTransactionMessage = transactionMessage
    },
    cancelTransactionMessage: function () {
      this.selectedTransactionMessage = null
      this.updateMessage = null
    },
    updateTransactionMessage: function () {
      api.transactionMessages.detail.update({
        transactionMessageId: this.selectedTransactionMessage.id,
        message: this.updateMessage,
      }).then(() => {
        this.readTransactionMessageList()
        this.selectedTransactionMessage = null
        this.updateMessage = null
      }).catch((error) => {
        api.errors.create({ message: error })
      })
    },
  },
  mounted: function () {
    this.readTransaction()
    this.readTransactionMessageList()
  },
}
</script>

<style lang="sass" scoped>
.base
  display: flex

.contents
  .prev-link
    font-size: 20px
    color: #555

.list-contents
  padding: 30px
  @media (max-width: 768px)
    padding: 10px

.item-list
  width: 90%
  margin-top: 20px
  @media (max-width: 768px)
    width: 100%

  th:nth-child(1), td:nth-child(1)
    border-right: 1px solid #0172b7 
  th, td
    width: 1px
    white-space: nowrap
  th:nth-child(2), td:nth-child(2)
    width: auto
    white-space: normal
    text-align: left
  
  .table-at-name
    text-align: left
  
  .button
    @media (max-width: 768px)
      padding: 5px 15px
      font-size: 12px

.controls
  display: flex
  align-items: center
  margin-top: 10px
  p
    margin-right: 10px

.at-icon
  width: 50px
  height: 50px
  padding: 7px
  background-color: #0172b7
  flex-shrink: 0
  @media (max-width: 768px)
    width: 30px
    height: 30px
    padding: 5px

.at-info
  @media (max-width: 768px)
    display: block
    text-align: left

.user-name
  color: #ff4500
  font-weight: bold

.message-view
  .message
    white-space: pre-wrap
  textarea
    width: 400px
    height: 100px
    border: 1px solid #ccc
    border-radius: 5px
    padding: 5px
    font-size: 16px
    @media (max-width: 768px)
      width: 100%
      font-size: 12px
    button
      padding: 8px 30px
      font-size: 14px
  .date
    color: #888

.form-contents
  margin-top: 30px
  margin-bottom: 30px
  h3
    margin-bottom: 10px
  textarea
    width: 400px
    height: 100px
    border: 1px solid #ccc
    border-radius: 5px
    padding: 5px
    font-size: 16px
    @media (max-width: 768px)
      width: 100%
      font-size: 12px
  button
    padding: 8px 30px
    font-size: 14px

.loading
  width: 50px
  height: 50px
  margin: 10px
  border-radius: 5px
  background-color: #0172b7
</style>
