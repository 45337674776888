<template>
  <aside>
    <div class="header">
      <router-link to="/">
        <h1 class="title">Asirtie<img class="logo" src="@/assets/logo.png" alt="Asirtie logo"></h1>
      </router-link>
    </div>
    <ul class="menu">
      <li>
        <router-link class="menu-link" to="/atcreate">Asirtieを使う</router-link>
      </li>
      <li>
        <router-link class="menu-link" to="/atlist">Asirtie一覧</router-link>
      </li>
      <li>
        <router-link class="menu-link" to="/diagram">Asirtie関係図</router-link>
      </li>
      <li @click="logout" ref="logoutButton">
        <span class="menu-link">ログアウト</span>
      </li>
    </ul>
  </aside>
</template>

<script>
import api from '@/api'

export default {
  name: 'SideBar',
  methods: {
    logout() {
      this.$refs.logoutButton.classList.add('loading_blue')

      api.auth.logout().then(() => {
        document.cookie = "token=; max-age=0; path=/;"
        document.cookie = "userid=; max-age=0; path=/;"
        this.$router.push('/login/')
      }).catch((error) => {
        api.errors.create({ message: error })
        this.$refs.logoutButton.classList.remove('loading_blue')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
aside
  height: 100vh
  width: 300px
  border-right: 2px solid #eee
  background-color: #fff
  position: fixed

  @media (max-width: 768px)
    display: none

.header
  height: 70px
  border-bottom: 2px solid #eee
  display: flex
  justify-content: center
  align-items: center
  padding: 0 15px
  .title
    display: flex
    align-items: center
  .logo
    height: 2em
    margin-left: 5px

.menu

  li
    display: flex
    align-items: center
    background-color: #fefefe
    border-bottom: 1px solid #eee
    transition: background-color 0.2s, color 0.2s
    cursor: pointer

    &:hover
      background-color: darken(#fefefe, 10%)

  .menu-link
    width: 100%
    height: 100%
    padding: 20px 0px 20px 30px
    display: flex
    align-items: center
</style>
