<template>
  <div class="base">
    <SideBar />
    <div class="contents">
      <HeaderBar />
      <div class="home-contents">
        <p>ようこそ Asirtieへ</p>
        <p>これは研修にターゲットをあてSocial Capitalがどのように働くかを見るプロジェクトです｡</p>
        <p>人と人の繋がりを通じて人から人へ(自分も含む)のメッセージとして相手(あるいは未来の自分)に｢繋がり｣を伝えるものです｡</p>
        <p>研修生の皆さんへ:<br>まず最初にAsirtieを自分で発行してください｡その後自分でなにか気づきを感じたり誰かにヒントを与えたり､与えられたりしたときにメッセージをその人(自分も含む)にお渡しください｡QRコードを見せるだけで渡すことができます｡</p>
        <p>Asirtieを用いてこの繰り返しを進めていきます｡</p>
        <p>あなたが主役となって繋がっていくことができます｡</p>
        <p>このように伝承していく仕組がAsirtieです｡</p>
        <p>今回はトライアルとして進めています｡そのため1年後にはこAsirtieは消滅することをご了承ください｡</p>
        <p>本プロジェクトで得られたメールアドレスなどの情報はこのプロジェクトの内部で厳重に保管し自分が含まれる関係図以外は外部の人からはアクセスできません｡なおご意見等は 
info@asirtie.comまでお寄せください｡</p>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "../components/HeaderBar.vue"
import SideBar from "../components/SideBar.vue"

export default {
  name: 'HomePage',
  components: {
    HeaderBar,
    SideBar,
  }
}
</script>

<style lang="sass" scoped>
.base
  display: flex

.home-contents
  padding: 30px
  width: 60em
  @media (max-width: 768px)
    padding: 20px
    width: 100%

  p
    margin-bottom: 20px
    font-size: 1.2em

    &:first-child
      font-weight: bold
      font-size: 1.4em
      border-bottom: 2px solid #0172b7
</style>
