<template>
  <HeaderBar :show-news="true" />
  <main>
    <div class="login-box">
      <p>Asirtie送信に成功しました。</p>
      <div class="at-review" v-if="isLoaded">
        <img
          :class="transaction.product.shape == 1 ? 'at-icon' : 'at-icon shape-circle'"
          :style="'background-color: ' + transaction.product.color"
          :src="transaction.product.icon.url">
        <div class="at-info">
          <div class="at-name">{{ transaction.product.name }}</div>
          <div class="at-no">No. {{ transaction.index }} / <span class="at-amount">{{ transaction.product.amount }}</span></div>
        </div>
      </div>
      <div class="loading_blue" v-else></div>
      <div class="trade-info" v-if="isLoaded">
          <div class="from">From: {{ transaction.from_user.profile.name }}</div>
          <div class="arrow">→</div>
          <div class="to">To: {{ transaction.to_user.profile.name }}</div>
        </div>
      <div class="next">
        <router-link class="next-link" to="/atcreate/">ホームに戻る</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import api from '@/api'
import HeaderBar from '../components/HeaderBar.vue'

export default {
  name: 'TradedPage',
  components: {
    HeaderBar,
  },
  data: function () {
    return {
      isLoaded: false,
      transaction: null,
    }
  },
  methods: {
    resend() {}
  },
  mounted: function () {
    api.transactions.detail.read({ transactionId: this.$route.query.transactionId }).then((response) => {
      console.log(response.data)
      this.transaction = response.data
    }).catch((error) => {
      api.errors.create({ message: error })
    }).finally(() => {
      this.isLoaded = true
    })
  }
}
</script>

<style lang="sass" scoped>
main
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  text-align: center

.loading_blue
  width: 100px
  height: 100px
  background-color: lighten(#deb54c, 30%)
  margin: 20px auto 0 auto
  border-radius: 5px

.at-review
  background-color: lighten(#deb54c, 30%)
  border-radius: 5px
  padding: 10px
  margin-top: 20px

.trade-info
  margin-top: 20px

.next
  margin-top: 20px
.next-link
  color: #0172b7

header
  width: 100%
</style>
