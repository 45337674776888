import moment from 'moment'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.config.globalProperties.$moment = function (date) {
    return moment(date).format('YYYY/MM/DD HH:mm:ss')
}

app.config.globalProperties.$user = {
    profile: {
        name: null,
    },
    mail: null,
    isVerified: null,
}

app.use(router).mount('#app')
