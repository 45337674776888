<template>
  <main>
    <div class="login-box">
      <h1 class="site-title">Asirtie</h1>
      <div class="logo">
        <img src="@/assets/logo.png" alt="">
      </div>
      <div class="site-description">
        Asirtieは人と人の繋がりを伝えるものです｡
      </div>
      <form  class="login-form" @submit.prevent="login">
        <input type="email" placeholder="メールアドレス" v-model="mail" required>
        <input type="password" placeholder="パスワード" v-model="password" required>
        <button type="submit" class="button submit-button" ref="loginButton">ログイン</button>
      </form>
      <div class="message-field" v-if="errorMessage">
        <p class="error-message">{{ errorMessage }}</p>
      </div>
      <div class="register">
        <p>アカウントをお持ちでない方は</p>
        <router-link
          class="register-link"
          :to="token ? `/signup/?qr=${token}` : '/signup/'">
          こちらで新規登録
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import api from '@/api'

export default {
  name: 'LoginPage',
  data: function () {
    return {
      mail: null,
      password: null,
      errorMessage: null,
      token: this.$route.query.token,
    }
  },
  methods: {
    login() {
      this.$refs.loginButton.classList.add('loading')
      this.errorMessage = null

      api.auth.login({
        mail: this.mail,
        password: this.password,
      }).then((response) => {

        this.$user = response.data.user

        document.cookie = `token=${response.data.token}; path=/`
        document.cookie = `userId=${response.data.user.id}; path=/`
        const next = this.$route.query.next
        if (next && this.token) {
          api.qr.verify({ token: this.token }).then((response) => {
            this.$router.push('/traded_message?transactionId=' + response.data.id)
          }).catch((error) => {
            api.errors.create({ message: error })
            this.$router.push('/')
          })
        } else {
          this.$router.push('/')
        }
      }).catch((error) => {
        switch (error.response.status) {
          case 403:
          case 404:
            this.errorMessage = 'メールアドレスまたはパスワードが間違えています。'
            break
          default:
            this.errorMessage = 'サーバーに問題が発生しました。時間をおいて再度実行してください。'
            break
        }

        this.$refs.loginButton.classList.remove('loading')
      })
    }
  },
  mounted: function () {
    const error = this.$route.query.error
    switch (error) {
      case '1':
        this.errorMessage = '既に認証済みであるか、有効期限が切れ無効な認証URLです。'
        break
      case '2':
        this.errorMessage = '無効なQRコードです。'
        break
      case '3':
        this.errorMessage = '送信できるAsirtieの上限を超えています。'
        break
      default:
        break
    }
  }
}
</script>

<style lang="sass" scoped>
main
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center

.site-title
  font-size: 3.5em

.logo
  img
    height: 70px

.login-box
  padding: 30px
  border-radius: 5px
  text-align: center
  width: 600px

  @media (max-width: 768px)
    width: 100%

.login-form
  margin-top: 50px

  input
    display: block
    border: 1px solid #062f48
    border-radius: 5px
    padding: 15px
    width: 380px
    margin: 20px auto
    outline: none
    font-size: 1.2em

    @media (max-width: 768px)
      width: 90%
  
  .button
    font-size: 1.2em
    width: 10em
    height: 2.5em
    margin-top: 20px

.message-field
  margin-top: 20px

.register
  margin-top: 20px
  
  .register-link
    color: #0172b7
</style>
