import axios from 'axios'

// const baseUrl = 'http://127.0.0.1:8000/api/'
const baseUrl = 'https://www.asirtie.com/api/'

function getHeader() {
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

    const baseHeader = {
        'Content-Type': 'application/json',
    }
    
    const cookies = document.cookie.split('; ')
    const parsedCookies = {}
    
    cookies.forEach(cookie => {
      const [key, value] = cookie.split('=')
      parsedCookies[key] = decodeURIComponent(value)
    });
    
    if (parsedCookies.token && parsedCookies.userId) {
        baseHeader['X-ASIRTIE-KEY'] = parsedCookies.token
        baseHeader['X-USER-ID'] = parsedCookies.userId
    }

    if (parsedCookies.csrftoken) {
        baseHeader['X-CSRFToken'] = parsedCookies.csrftoken
    }

    return baseHeader
}

const api = {
    getHeader: () => getHeader(),
    auth: {
        signup: async ({ name, mail, password, qr }) => axios.post(
            baseUrl + 'users/',
            {
                name: name,
                mail: mail,
                password: password,
                qr: qr,
            },
            { headers: getHeader() },
        ),
        login: async ({ mail, password }) => axios.post(
            baseUrl + 'auth/login/',
            {
                mail: mail,
                password: password
            },
            { headers: getHeader() },
        ),
        logout: async () => axios.post(baseUrl + 'auth/logout/', {}, { headers: getHeader() }),
        confirm: async () => axios.post(baseUrl + 'auth/confirm/', {}, { headers: getHeader() }),
    },
    users: {
        detail: {
            read: async ({ userId }) => axios.get(
                baseUrl + `users/${userId}/`,
                { headers: getHeader() },
            ),
        },
        resend: async ({ userId }) => axios.post(
            baseUrl + 'users/resend/',
            {
                userId: userId,
            },
            { headers: getHeader() },
        ),
    },
    products: {
        read: async ({ userId }) => axios.get(
            baseUrl + `products/?userId=${userId}`,
            { headers: getHeader() },
        ),
        create: async ({
            name,
            shape,
            color,
            imageId,
            amount,
        }) => axios.post(
            baseUrl + 'products/',
            {
                name: name,
                shape: shape,
                color: color,
                imageId: imageId,
                amount: amount,
            },
            { headers: getHeader() },
        ),
        detail: {
            read: async ({ productId }) => axios.get(
                baseUrl + `products/${productId}/`,
                { headers: getHeader() },
            ),
            delete: async ({ productId }) => axios.delete(
                baseUrl + `products/${productId}/`,
                { headers: getHeader() },
            ),
        },
    },
    transactions: {
        read: async ({
            userId=null,
            isActive=null,
            isDiagram=null,
            order=null,
            productId=null,
            index=null,
        }) => {
            let url = baseUrl + 'transactions/?'
            if (userId) url += `userId=${userId}&`
            if (isActive) url += `isActive=${isActive}&`
            if (isDiagram) url += `isDiagram=${isDiagram}&`
            if (order) url += `order=${order}&`
            if (productId) url += `productId=${productId}&`
            if (index) url += `index=${index}&`
            return axios.get(url, { headers: getHeader() })
        },
        create: async ({ productId }) => axios.post(
            baseUrl + 'transactions/',
            {
                productId: productId,
            },
            { headers: getHeader() },
        ),
        detail: {
            read: async ({ transactionId }) => axios.get(
                baseUrl + `transactions/${transactionId}/`,
                { headers: getHeader() },
            ),
        },
    },
    transactionMessages: {
        read: async ({ transactionId }) => axios.get(
            baseUrl + `transaction_messages/?transactionId=${transactionId}`,
            { headers: getHeader() },
        ),
        create: async ({ transactionId, message, latitude=null, longitude=null, address=null }) => axios.post(
            baseUrl + 'transaction_messages/',
            {
                transactionId: transactionId,
                message: message,
                latitude: latitude,
                longitude: longitude,
                address: address,
            },
            { headers: getHeader() },
        ),
        detail: {
            update: async ({ transactionMessageId, message }) => axios.put(
                baseUrl + `transaction_messages/${transactionMessageId}/`,
                {
                    message: message,
                },
                { headers: getHeader() },
            ),
        },
    },
    qr: {
        verify: async ({ token }) => axios.get(
            baseUrl + `qr/verify/?token=${token}`,
            { headers: getHeader() },
        ),
        check: async ({ transactionId }) => axios.get(
            baseUrl + `qr/check/?transactionId=${transactionId}`,
            { headers: getHeader() },
        ),
    },
    images: {
        read: async () => axios.get(
            baseUrl + `images/`,
            { headers: getHeader() },
        ),
    },
    addresses: {
        read: async ({ latitude, longitude }) => axios.get(
            baseUrl + `addresses/?latitude=${latitude}&longitude=${longitude}`,
            { headers: getHeader() },
        ),
    },
    errors: {
        create: async ({ message }) => axios.post(
            baseUrl + 'errors/',
            {
                message: message,
            },
        ),
    },
}

export default api
