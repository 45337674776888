<template>
  <main>
    <div class="login-box">
      <p>入力したメールアドレスに確認メールを送信しました。</p>
      <p>内容を確認し、ログインしてください。</p>
      <div class="login-form">
        <button
          class="button submit-button"
          v-on:click="resend"
          ref="resendButton">登録確認メールを再送信</button>
      </div>
      <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
      <div class="login">
        <p>既にアカウントをお持ちの方は</p>
        <router-link class="login-link" to="/login">こちらからログイン</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import api from '@/api'

export default {
  name: 'SignupVerifyPage',
  data: function () {
    return {
      isLoading: false,
      errorMessage: null,
    }
  },
  methods: {
    resend() {
      this.$refs.resendButton.classList.add('loading')
      this.errorMessage = null

      api.users.resend({
        userId: this.$route.query.userId,
      }).then((response) => {
        const userId = response.data.id
        this.$router.push(`/resend/?userId=${userId}`)
      }).catch((error) => {
        switch (error.response.status) {
          case 422:
            this.errorMessage = '認証メールの送信に失敗しました。使用可能なメールアドレスか確認してください。'
            break
          default:
            this.errorMessage = 'サーバーに問題が発生しました。時間をおいて再度実行してください。'
            break
        }
        api.errors.create({ message: error })
      }).finally(() => {
        this.$refs.resendButton.classList.remove('loading')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
main
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center

.site-title
  font-size: 3.5em

.login-box
  padding: 30px
  border-radius: 5px
  text-align: center
  width: 600px

  @media (max-width: 768px)
    width: 100%

.login-form
  margin-top: 20px

  .button
    font-size: 1.2em
    height: 2.5em
    margin-top: 20px

.login
  margin-top: 20px
  
  .login-link
    color: #0172b7

.error-message
  margin-top: 20px
</style>
