<template>
  <main>
    <div class="login-box">
      <h1 class="site-title">Asirtie</h1>
      <div class="logo">
        <img src="@/assets/logo.png" alt="">
      </div>
      <p class="confirm-message">
        私は、<b>｢研修における人と人の繋がりを示すAsirtie｣</b>について、
        以下の説明書を読み、内容について十分理解しました。
        下記の｢同意する｣ボタンをクリックすることにより私がこのプロジェクトに
        参加することを自由意思で決定したことを示します。
      </p>
      <div class="controls">
        <button class="button success-button" @click="agree()">同意する</button>
        <button class="button danger-button" @click="disagree()">同意しない</button>
      </div>
      <div class="explanation">
        <h3>｢研修における人と人の繋がりを示すAsirtie｣についての説明書</h3>
        <ol class="top-level">
          <li>
            本プロジェクトは｢研修における人と人の繋がりを示すAsirtie｣をメッセージとして
            相手（あるいは未来の自分）に伝え、「繋がり」を表現するものです。
          </li>
          <li>
            <h4>使い方</h4>
            <ol>
              <li>
                本説明書に同意していた開ける場合は下にある同意するのボタンを押した後､
                次のページでニックネームとメールアドレスとパスワードを登録します｡
              </li>
              <li>
                Asirtieを自分で発行し、繋がりを記録したい他者に渡します｡
                QRコードを見せるだけで渡すことができます｡
              </li>
              <li>
                Asirtieを貰った人は同じように自身が主役となってAsirtieを発行し、
                渡すことでさらに繋がりを記録していくことができます｡
              </li>
              <li>
                このように繋がりをAsirtieというトークンを使って記録していく仕組みがAsirtieです｡
                自分で複数の異なるAsirtieを作ることもできます｡
              </li>
            </ol>
          </li>
          <li>
            <h4>取得するデータ</h4>
            <ul>
              <li>
                本プロジェクトに参加することで、登録時間､メールアドレス､パスワード､ニックネーム､
                作成したAsirtieの情報、アンケート情報（任意参加）を取得します｡なおこれらの情報は
                「国立大学法人室蘭工業大学個人情報等管理規程」に基づき厳重に管理します｡
              </li>
              <li>
                <p>本プロジェクトで取得した情報は，以下の目的のために室蘭工業大学が使用します。</p>
                <ul>
                  <li>サービス提供のため</li>
                  <li>地域のSocial Capitaの分析のため</li>
                  <li>Asirtieの効果測定のため</li>
                </ul>
              </li>
              <li>
                取得したデータの保管期間は、１年です。変更する際にはお知らせします｡
              </li>
              <li>
                取得したデータは、室蘭工業大学が，分析結果を個人が特定できないように統計処理し，
                論文、報告書、プレゼンテーション資料、インターネットホームページ等の著作物に
                掲載する場合があります．
              </li>
            </ul>
          </li>
          <li>
            <h4>利用者の権利</h4>
            <ul>
              <li>利用者は自らの自由意志でいつでもプロジェクトから抜けることができる。</li>
              <li>万一不利益をこうむった場合に、室蘭工業大学に対して申し立てを行うことができる。</li>
            </ul>
          </li>
        </ol>
        <p>なおご意見等は  info@asirtie.comまでお寄せください｡</p>
      </div>
      <p class="error-message" v-if="isDisagree">同意がなされませんでした。このページを閉じてください。</p>
    </div>
  </main>
</template>

<script>
export default {
  name: 'ConfirmPage',
  data: function () {
    return {
      qr: this.$route.query.qr,
      isDisagree: false,
    }
  },
  methods: {
    agree: function () {
      this.isDisagree = false
      this.$router.push(`/signup/?qr=${this.qr}`)
    },
    disagree: function () {
      window.close()
      this.isDisagree = true
    },
  },
}
</script>

<style lang="sass" scoped>
main
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  @media (max-width: 768px)
    height: auto

.site-title
  font-size: 3.5em

.logo
  img
    height: 70px

.login-box
  padding: 30px
  border-radius: 5px
  text-align: center

  @media (max-width: 768px)
    width: 100%

  .button
    font-size: 1.2em
    height: 2.5em

.confirm-message
  width: 700px
  margin-top: 20px
  @media (max-width: 768px)
    width: auto

.explanation
  width: 680px
  height: 300px
  background-color: #fff
  overflow-y: scroll
  margin: 20px auto
  padding: 10px
  text-align: left
  @media (max-width: 768px)
    width: auto
  
  h3
    margin-bottom: 10px
    border-bottom: 1px solid #062f48
    padding: 5px
    text-align: center
  h4
    font-weight: normal
  ol
    margin-left: 2em
    li
      list-style: decimal
      ol
        margin-top: 10px
        li
          list-style: none
          counter-increment: cnt
          &::before
            content: counter(cnt) ")"
    &.top-level
      margin-top: 20px
      li
        margin-bottom: 20px
  ul
    margin-top: 10px
    margin-left: 2em
    li
      list-style: disc
      ul
        margin-top: 10px
        li
          list-style: circle

.controls
  margin: 20px auto 0 auto
  display: flex
  justify-content: center
  .button:first-child
    margin-right: 20px
</style>
