<template>
    <div class="wrapper" ref="selectImage">
      <div class="input-field" @click="toggle()">
        <img
          v-if="selectedImage"
          :src="selectedImage.url"
          :style="'background-color: ' + color"
          :class="shape === 2 ? 'selected-image shape-circle' : 'selected-image'">
        <p v-else>{{ message }}</p>
        <img class="arrow" src="@/assets/arrow_dropdown.png">
    </div>
      <ul class="menu" v-show="isShow">
        <template v-for="(item, i) in data" :key="item.id">
          <li>
            <img
              :src="item.url"
              :style="'background-color: ' + color"
              :class="shape === 2 ? 'shape-circle' : ''"
              :data-image-index="i"
              @click="select">
          </li>
          <br v-if="(i + 1) % 4 === 0">
        </template>
      </ul>
    </div>
</template>

<script>
export default {
  name: 'SelectImage',
  props: {
    message: {
      type: String,
      require: false,
      default: ''
    },
    data: {
      type: Array,
      require: true,
    },
    shape: {
      type: Number,
      require: false,
      default: 1,
    },
    color: {
      type: String,
      require: false,
      default: "#0172b7",
    },
  },
  data: function () {
    return {
      isShow: false,
      selectedImage: null,
    }
  },
  methods: {
    show: function () {
      this.isShow = true
    },
    hide: function () {
      this.isShow = false
    },
    toggle: function () {
      this.isShow = !this.isShow
    },
    select: function (e) {
      this.selectedImage = this.data[e.target.dataset.imageIndex]
      this.$emit('handler', this.selectedImage)
      this.hide()
    }
  },
  mounted() {
    window.addEventListener('click', this._onBlurHandler = (event) => {
      if (this.$refs.selectImage.contains(event.target)) {
        return
      }
      this.hide()
    });
  },
  beforeUnmount() {
    window.removeEventListener('click', this._onBlurHandler);
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  margin: 20px auto
.input-field
  display: flex
  align-items: center
  width: 100%
  color: #000
  background-color: #fff
  border: 1px solid #000
  font-size: 1.1em
  border-radius: 5px
  padding: 10px
  position: relative

  .selected-image
    width: 30px
    height: 30px
    padding: 5px

  .arrow
    width: 10px
    height: 10px
    position: absolute
    right: 5px
    top: calc(50% - 5px)

.menu
  position: absolute
  background-color: #fff
  border: 1px solid #aaa
  border-radius: 5px
  overflow-y: scroll
  z-index: 100

  li
    display: inline-flex
    align-items: center
    padding: 5px 10px
    border-bottom: 1px solid #ccc
    &:last-child
      border-bottom: none
    img
      width: 50px
      height: 50px
      padding: 10px
      background-color: #0172b7
      cursor: pointer
</style>
