<template>
  <div class="base">
    <SideBar />
    <div class="contents">
      <HeaderBar />
      <div class="form-contents">
        <h2>Asirtieを使う</h2>
        <p class="description">必要事項を入力したら「新しいAsirtieを使えるようにする」ボタンを押して下さい。</p>
        <form @submit.prevent="createProduct()">
          <fieldset class="at-form">
            <div class="at-left">
              <div class="at-input-field">
                <input type="text" placeholder="Asirtie名を入力" v-model="name">
              </div>
              <div class="at-2-columns">
                <div class="at-input-field">
                  <select name="at-amount" v-model.number="selectedAmount">
                    <option value="0" hidden>Asirtie発行数</option>
                    <option value="10">10 Asirtie</option>
                    <option value="50">50 Asirtie</option>
                    <option value="100">100 Asirtie</option>
                  </select>
                </div>
                <div class="at-input-field">
                  <select name="at-shape" v-model.number="selectedShape">
                    <option value="0" hidden>アイコン形状</option>
                    <option value="1">四角形</option>
                    <option value="2">円形</option>
                  </select>
                </div>
              </div>
              <SelectImage
                class="at-input-field"
                message="アイコンイメージを選択"
                :data="figures"
                :shape="selectedShape"
                :color="selectedColor"
                @handler="selectedFigureHandler" />
              <ColorPalette
                class="at-input-field"
                message="アイコンの色を選択"
                :colors="colors"
                @handler="selectedColorHandler" />
            </div>
            <div class="at-right">
              <div class="at-review">
                <img
                  :class="selectedShape == 2 ? 'at-icon shape-circle' : 'at-icon'"
                  :style="'background-color: ' + selectedColor"
                  :src="selectedFigure ? selectedFigure.url : require('@/assets/figure_carrot.png')">
                <div class="at-info">
                  <div class="at-name">{{ name ? name : '[Asirtie名]'}}</div>
                  <div class="at-amount">{{ selectedAmount ? selectedAmount + ' Asirtie': '[発行数]'}}</div>
                </div>
              </div>
              <div class="at-controll">
                <button
                  class="button submit-button"
                  ref="productCreateButton"
                  :disabled="!name ||
                    !selectedAmount ||
                    !selectedFigure ||
                    !selectedColor ||
                    !selectedShape">新しいAsirtieを使えるようにする</button>
              </div>
            </div>
          </fieldset>
        </form>
        <Transition name="fade">
          <div v-if="isLoaded && productList.length > 0">
            <h2>使えるAsirtie一覧</h2>
            <div class="at-controls">
              <span>選択したAsirtieを：</span>
              <button 
                class="button success-button"
                @click="$refs.sendModal.show(); createTransaction();"
                :disabled="selectedProduct == null">
                使う
              </button>
              <button class="button danger-button" @click="$refs.deleteModal.show()" :disabled="selectedProduct == null">削除する</button>
            </div>
            <table class="item-list">
              <thead>
                <tr>
                  <th>選択</th>
                  <th>発行済みAsirtie</th>
                  <th>Asirtie残量</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, i) in productList" :key="i">
                  <td class="item-list-controls">
                    <input type="radio" name="at-control" v-model="selectedProduct" :value="product">
                  </td>
                  <td>
                    <div class="at-review">
                      <img
                        :class="product.shape == 1 ? 'at-icon' : 'at-icon shape-circle'"
                        :style="'background-color: ' + product.color"
                        :src="product.icon.url">
                      <div class="at-info">
                        <div class="at-name">{{ product.name }}</div>
                      </div>
                    </div>
                  </td>
                  <td>{{ product.usage }} / {{ product.amount }} Asirtie</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Transition>
      </div>
    </div>
    <MyModal title="Asirtieを使う" ref="sendModal" @hide="clearQrInterval">
      <div class="at-review">
        <img
          :class="selectedProduct.shape == 1 ? 'at-icon' : 'at-icon shape-circle'"
          :style="'background-color: ' + selectedProduct.color"
          :src="selectedProduct.icon.url">
        <div class="at-info">
          <div class="at-name">{{ selectedProduct.name }}</div>
          <div class="at-amount">{{ selectedProduct.usage }} / {{ selectedProduct.amount }} Asirtie</div>
        </div>
      </div>
      <div class="qr-code-contents">
        <vue-qrcode v-if="!qrLoading" :value="qrData.content" :options="qrData.option" tag="img" />
        <div class="qr-loading loading_blue" v-else>
          QRコード生成中
        </div>
        <p>送信先ユーザーに読み取ってもらうことでAsirtieが送信されます。</p>
      </div>
    </MyModal>
    <DeleteModal title="Asirtieを削除する" :action="deleteProduct" :after="readProductList" ref="deleteModal">
      <div class="at-review">
        <img
          :class="selectedProduct.shape == 1 ? 'at-icon' : 'at-icon shape-circle'"
          :style="'background-color: ' + selectedProduct.color"
          :src="selectedProduct.icon.url">
        <div class="at-info">
          <div class="at-name">{{ selectedProduct.name }}</div>
          <div class="at-amount">{{ selectedProduct.usage }} / {{ selectedProduct.amount }} Asirtie</div>
        </div>
      </div>
      <p class="delete-message">送信したAsirtieも含めて削除されます。本当に削除しますか？</p>
    </DeleteModal>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import api from '@/api'
import HeaderBar from "../components/HeaderBar.vue"
import SideBar from "../components/SideBar.vue"
import MyModal from "../components/MyModal.vue"
import DeleteModal from "../components/DeleteModal.vue"
import SelectImage from "../components/SelectImage.vue"
import ColorPalette from "../components/ColorPalette.vue"

export default {
  name: 'ATCreatePage',
  components: {
    HeaderBar,
    SideBar,
    MyModal,
    DeleteModal,
    SelectImage,
    ColorPalette,
    VueQrcode,
  },
  data: function () {
    return {
      name: null,
      selectedAmount: 0,
      selectedShape: 0,
      selectedFigure: null,
      selectedColor: "#F44336",
      selectedProduct: null,
      productList: [],
      figures: [],
      colors: [
        "#F44336", "#E91E63", "#9C27B0", "#7E57C2",
        "#5C6BC0", "#42A5F5", "#29B6F6", "#26C6DA",
        "#26A69A", "#66BB6A", "#9CCC65", "#D4E157",
        "#FFEE58", "#FFA726", "#FF7043", "#8D6E63",
        "#424242", "#78909C",
      ],
      isLoaded: false,
      qrLoading: false,
      qrData: {
        content: "",
        option: {
          errorCorrectionLevel: "M",
          maskPattern: 0,
          margin: 5,
          scale: 2,
          width: 200,
          color: {
            dark: "#000000FF",
            light: "#FFFFFFFF",
          },
        },
      },
      qrInterval: null,
    }
  },
  methods: {
    readProductList: function () {
      this.selectedProduct = null
      api.products.read({ userId: api.getHeader()['X-USER-ID'] }).then((response) => {
        this.productList = response.data
        this.isLoaded = true
      }).catch((error) => {
        api.errors.create({ message: error })
        this.$refs.productCreateButton.classList.remove('loading')
      })
    },
    createProduct: function () {
      this.$refs.productCreateButton.classList.add('loading')

      api.products.create({
        name: this.name,
        shape: this.selectedShape,
        color: this.selectedColor,
        imageId: this.selectedFigure.id,
        amount: this.selectedAmount,
      }).then(() => {
        this.name = null
        this.selectedAmount = 0
        this.selectedShape = 0
        this.selectedColor = "#F44336"
        this.selectedFigure = null
        this.readProductList()
      }).catch((error) => {
        api.errors.create({ message: error })
      }).finally(() => {
        this.$refs.productCreateButton.classList.remove('loading')
      })
    },
    createTransaction: async function () {
      this.qrLoading = true

      try {
        const product = (await api.products.detail.read({ productId: this.selectedProduct.id })).data

        let url, transactionId
        if (!product.pending) {
          const activation = (await api.transactions.create({ productId: product.id })).data
          url = `http://${location.host}/api/qr/verify/?token=${activation.token}`
          transactionId = activation.transaction.id
        } else {
          url = `http://${location.host}/api/qr/verify/?token=${product.pending.token}`
          transactionId = product.pending.transaction
        }
        this.qrData.content = url

        this.clearQrInterval()
        this.qrInterval = setInterval(this.checkTransaction, 2000, transactionId)
        
      } catch (error) {
        api.errors.create({ message: error })
      }

      this.qrLoading = false
    },
    deleteProduct: async function () {
      return api.products.detail.delete({ productId: this.selectedProduct.id })
    },
    selectedFigureHandler: function (image) {
      this.selectedFigure = image
    },
    selectedColorHandler: function (color) {
      this.selectedColor = color
    },
    checkTransaction: async function (transactionId) {
      return api.qr.check({ transactionId }).then((response) => {
        console.log(response.data)
        if (!response.data.is_active) {
          return true
        }

        window.location.href = `/traded/?transactionId=${transactionId}`
      }).catch(() => true)
    },
    clearQrInterval: function () {
      if (this.qrInterval) {
        clearInterval(this.qrInterval)
      }
      this.qrInterval = null
    },
  },
  mounted: function () {
    this.readProductList()

    api.images.read().then((response) => {
      this.figures = response.data
    }).catch((error) => {
      api.errors.create({ message: error })
    })
  },
}
</script>

<style lang="sass" scoped>
.base
  display: flex

.description
  margin: 10px

.form-contents
  padding: 30px
  @media (max-width: 768px)
    padding: 10px

.at-form
  display: flex
  align-items: center
  @media (max-width: 768px)
    display: block

.at-left
  padding: 20px
  border-right: 1px solid #062f48
  @media (max-width: 768px)
    border-right: none
    padding: 0 20px

  .at-input-field
    input, select
      margin-left: 0
  .at-input-field-color
    display: flex
    align-items: center
    input[type="color"]
      width: 2em
      margin: 0 20px
      padding: 2px
  .at-figure
    margin-top: 20px
    input[type="radio"]
      display: none
    li
      display: inline-block
      label
        position: relative
        display: block
        width: 75px
        height: 75px
        margin: 10px
        background-color: #062f48
        box-sizing: content-box
        cursor: pointer
        transition: border-radius 0.2s
        @media (max-width: 768px)
          width: 60px
          height: 60px
          margin: 5px

      input[type="radio"]:checked+label
        border: 3px solid #7ee9f9
        margin: 7px
        @media (max-width: 768px)
          margin: 2px
        &::after
          content: ""
          width: 10px
          height: 10px
          border-radius: 50%
          background-color: #7ee9f9
          position: absolute
          bottom: 10px
          right: 10px

  .at-2-columns
    @media (max-width: 768px)
      display: flex
      .at-input-field
        width: 50%
        select
          margin: 0
        &:first-child
          margin-right: 10px


.at-right
  padding: 20px
  .at-controll
    display: flex
    justify-content: center
    align-items: center

  .submit-button
    font-size: 1.2em
    height: 2.5em
    margin-top: 20px

.at-review
  display: flex
  align-items: center

  .at-icon
    width: 150px
    height: 150px
    padding: 5%
    background-color: #0172b7
    flex-shrink: 0
    transition: border-radius 0.2s
    @media (max-width: 768px)
      width: 80px
      height: 80px

  .at-info
    margin-left: 20px
    padding: 10px
    display: flex
    align-items: flex-end
    border-bottom: 5px solid #0172b7
    @media (max-width: 768px)
      display: block
      text-align: center
      width: 100%

  .at-name
    font-size: 2.4em
    @media (max-width: 768px)
      font-size: 1.6em

  .at-amount
    margin-left: 20px
    font-size: 2em
    @media (max-width: 768px)
      margin-left: 0
      font-size: 1.4em

.at-controls
  margin-top: 20px
  .button
    margin-right: 10px

.item-list
  width: auto
  margin-top: 10px
  @media (max-width: 768px)
    width: 100%

  .at-icon
    width: 60px
    height: 60px
    padding: 5%
    @media (max-width: 768px)
      width: 50px
      height: 50px
  .at-info
    padding: 0
    @media (max-width: 768px)
      margin-left: 5px
  .at-name
    font-size: 1.6em
    @media (max-width: 768px)
      font-size: 1.4em

  .item-list-controls
    input
      width: auto

  .button
    padding: 10px 15px
    font-size: 1em
    margin-left: 10px
    @media (max-width: 768px)
      padding: 5px 10px
      margin-left: 5px

    &:first-child
      margin: 0

.qr-code-contents
  margin-top: 20px
  text-align: center
  img
    width: 180px
    height: 180px

.modal
  .form-contents
    input
      margin: 10px auto

.delete-message
  margin-top: 20px
  text-align: center
  font-size: 1.1em

.qr-loading
  display: flex
  justify-content: center
  align-items: center
  height: 150px
</style>
